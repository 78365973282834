<template>
  <div class="welcome-box">
    <div>
      <p class="nickname">欢迎登录「{{userInfo.user_name}}」</p>
      <p>媒资库后台</p>
    </div>
  </div>
</template>
<script>
import local from '@/utils/localStroage.js'
// import list from '@/router/permissionRouter.js'
export default {
  data() {
    return {
    }
  },
  computed: {
    userInfo: () => {
      return local.get('userInfo')
    },
  }
}
</script>
<style lang="scss" scoped>
.welcome-box {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/welcome.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  //box-shadow: -3px -2px 5px #999;
  border-radius: 5px;
  div {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    overflow: hidden;
    p {
      width: 100%;
      text-align: center;
      color: #e63765;
      font-size: 76px;
    }
    .nickname {
      margin: 150px 0 30px 0;
      font-size: 50px;
    }
  }
}
</style>
